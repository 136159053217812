<template>
  <div id="module-edit-tab-info">

    <!-- Content Row -->
    <div class="vx-row mb-5">
      <div class="vx-col md:w-1/2 sm:w-full pt-3">
        <vs-input v-model="data_local.name" v-validate="'required'" class="w-full" label="Module Name" name="name" tabindex="1"/>
        <span v-show="errors.has('first_name')" class="text-danger text-sm">{{ errors.first('name') }}</span>
      </div>

      <div class="vx-col md:w-1/2 sm:w-full pt-3">
        <vs-input v-model="data_local.author_id" v-validate="'required'" class="w-full" label="Author ID" name="author_id"
                  tabindex="2"/>
        <span v-show="errors.has('author_id')" class="text-danger text-sm">{{ errors.first('author_id') }}</span>
      </div>

      <div class="vx-col w-full pt-3">
        <vs-input v-model="data_local.end_of_module_iframe_url" v-validate="'required'" class="w-full" label="End Of Module Feedback Form URL" name="end_of_module_iframe_url"
                  tabindex="2"/>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateModuleUpdate">Save Changes
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    initiateModuleUpdate() {

      const payload = {
        name: this.data_local.name,
        author_id: this.data_local.author_id,
        end_of_module_iframe_url: this.data_local.end_of_module_iframe_url,
      };

      this.$vs.loading();

      this.$http.post(`modules/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>
