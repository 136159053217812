<!-- =========================================================================================
  File Name: ModuleEdit.vue
  Description: Module Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/module/pixinvent
========================================================================================== -->

<template>
  <div id="page-module-edit">

    <vs-alert :active.sync="module_not_found" color="danger" title="Module Not Found">
      <span>Module record with id: {{ $route.params.moduleId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/a/modules" class="text-inherit underline">All Modules</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="module_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <module-edit-tab-general :data="module_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-book" icon-pack="feather" label="Children">
            <div class="tab-text">
              <module-edit-tab-children :data="module_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import ModuleEditTabGeneral from './ModuleEditTabGeneral';
import ModuleEditTabChildren from './ModuleEditTabChildren';

// Store Module

export default {
  components: {
    ModuleEditTabGeneral,
    ModuleEditTabChildren,
  },
  data() {
    return {
      module_data: null,
      module_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchModuleData(this.$route.params.moduleId);
    },
  },
  methods: {
    fetchModuleData(moduleId) {

      this.$http.get(`modules/${moduleId}/rich`)
        .then(response => {

          if (response.data.data) {
            this.module_data = response.data.data;
          }

        })
        .catch(error => {
          this.$vs.loading.close();

          if (error.response.status === 404) {
            this.module_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    this.fetchModuleData(this.$route.params.moduleId);
  },
};

</script>
