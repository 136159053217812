<template>
  <div id="course-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="CourseIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">
        Module Lessons
      </span>
    </div>

    <div class="vx-row pb-10 pt-8">

      <div class="vx-col w-full">
        <label class="vs-input--label">
          Module Lessons
          &mdash;
          <a href="#" @click="openLessonSearch">search by name</a>
        </label>

        <vs-list>
          <draggable :list="data_local.lessons">
            <transition-group>
              <vs-list-item v-for="listItem in data_local.lessons" :key="listItem.id" class="list-item">
                <div slot="title" class="vs-list--title">
                  <vs-button :id="`list-item-${listItem.id}`" color="primary" size="small" @click="onOrderRemove(listItem.id)">
                    X
                  </vs-button>

                  {{ listItem.name }}
                </div>
              </vs-list-item>
            </transition-group>
          </draggable>

        </vs-list>

      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateCourseUpdate">
            Save Order
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>

    <vs-popup :active.sync="lesson_search_active" classContent="popup-example"
              title="Search for Lesson by Name">

      <vs-table
        :data="lesson_search_results"
        :sst="true"
        max-items="5"
        search

        @search="handleLessonSearch"
      >

        <template slot="header">
          <h3>Lessons</h3>
        </template>

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Duration</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(attach, index) in data" :key="attach.id">

            <vs-td :data="attach.id">
              {{ attach.id }}
            </vs-td>

            <vs-td :data="attach.name">
              {{ attach.name }}
            </vs-td>

            <vs-td :data="attach.duration">
              {{ attach.duration | duration }}
            </vs-td>

            <vs-td>
              <vs-button color="primary" size="small" type="border" @click="handleLessonSelected(attach)">
                Select
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vs-popup>

  </div>
</template>

<script>
import vSelect from 'vue-select';
import draggable from 'vuedraggable';

export default {
  components: {
    vSelect,
    draggable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],

      lesson_search_selected: [],
      lesson_search_active: false,
      lesson_search_results: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {

  },
  methods: {
    onOrderRemove(id) {

      this.$vs.loading({
        color: 'primary',
        container: `#list-item-${id}`,
        scale: 0.45,
      });

      this.$http.delete(`modules/${this.data_local.id}/lessons/${id}`)
        .then(response => {

          if (response.data) {
            this.$vs.loading.close();

            this.data_local.lessons = this.data_local.lessons.filter(lesson => {
              return lesson.id !== id;
            });

            return this.$vs.notify({
              title: 'Action Successful',
              text: `Successfully removed the lesson from this module`,
              color: 'success',
            });
          }

          throw new Error({response});
        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while remove the lesson';

          if (exception.response) {
            if (exception.response.data.error) {
              error = exception.response.data.error.description;
            }
          }

          return this.$vs.notify({
            title: 'Failed to remove lesson',
            text: error,
            color: 'danger',
          });

        });

      this.data_local.lessons = this.data_local.lessons.filter(lesson => {
        return lesson.id !== id;
      });
    },
    openLessonSearch() {
      this.lesson_search_active = true;
      return false;
    },
    handleLessonSearch(term) {

      if (!term || term.length <= 3) return;

      this.$http.get(`lessons?name=${term}&amount=5`)
        .then(response => {

          if (response.data) {
            this.lesson_search_results = response.data.data;
          }

        });

    },
    handleLessonSelected(lesson) {
      this.lesson_search_active = false;
      this.$vs.loading();

      const {id, name, duration} = lesson;

      this.$http.put(`modules/${this.data_local.id}/lessons/${id}`)
        .then(response => {

          if (response.data) {
            this.$vs.loading.close();

            this.data_local.lessons.push(lesson);

            return this.$vs.notify({
              title: 'Action Successful',
              text: `Successfully added the lesson to this module`,
              color: 'success',
            });
          }

          throw new Error({response});
        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while adding the lesson';

          if (exception.response) {
            if (exception.response.data.error) {
              error = exception.response.data.error.description;
            }
          }

          return this.$vs.notify({
            title: 'Failed to add lesson',
            text: error,
            color: 'danger',
          });

        });
    },
    initiateCourseUpdate() {

      const payload = {
        lesson_ids: this.data_local.lessons.map(lesson => {
          return lesson.id;
        }),
      };

      this.$vs.loading();

      this.$http.post(`modules/${this.data_local.id}`, payload)
        .then(async response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {
            response = await this.$http.get(`modules/${this.data_local.id}/rich`)
            this.data_local = response.data.data;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating the module';

          if (exception.response) {
            if (exception.response.data.error) {
              error = exception.response.data.error.description;
            }
          }

          return this.$vs.notify({
            title: 'Failed to update module',
            text: error,
            color: 'danger',
          });

        });
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style type="text/scss">
.avatar {
  width: 25rem;
}

.list-item {
  transition: all 1s
}
</style>
